
import { Vue, Component, Prop } from 'vue-property-decorator'

import { validateRules, validationRules } from '@/utils/rules'
@Component({})
export default class PaystubDetailMarkAsPaidModal extends Vue {
  @Prop({ type: Boolean, default: null }) readonly value!: boolean
  @Prop({ type: Number, default: null }) readonly totalSelected!: number
  @Prop({ type: Number, default: null }) readonly totalUnpaid!: number
  @Prop({ type: Number, default: null }) readonly totalReverted!: number
  @Prop({ type: Number, default: null }) readonly totalPaid!: number

  rules = validationRules
  paymentDate = null

  get totalSelectedLabel(): string {
    if (!this.totalSelected) {
      return null
    }

    if (this.totalSelected === 1) {
      return '1 Reservation selected'
    }

    return `${this.totalSelected} Reservations selected`
  }

  handleConfirm(): void {
    const form: any = this.$refs['form']
    if (!form.validate()) {
      return
    }
    this.$emit('confirm', this.paymentDate)
  }
}

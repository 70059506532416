
import { Vue, Component, Prop } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'
import { Action } from '@/models/dto/Action'
import paystub from '@/store/modules/paystub'
@Component({})
export default class PaystubDetailSidebarHeader extends Vue {
  @Prop({ default: 'Title' }) title!: string
  @Prop({ default: 'pending' }) status!: string

  sidebar = sidebar

  get statusLabel(): string {
    if (this.status.length < 2) {
      return this.status.toUpperCase()
    }
    return (
      this.status.charAt(0).toUpperCase() + this.status.slice(1).toLowerCase()
    )
  }

  get statusTagBackgroundColor(): string {
    switch (this.status.toLowerCase()) {
      case 'paid':
        return 'secondary-light'
      case 'reverted':
        return 'additional-red-2'
      case 'pending':
      default:
        return 'primary-orange-3'
    }
  }

  get statusTagBorderColor(): string {
    switch (this.status.toLowerCase()) {
      case 'paid':
        return 'secondary'
      case 'reverted':
        return 'error'
      case 'pending':
      default:
        return 'primary'
    }
  }
  // Actions for Send to Driver, View PDF, and Revert Pay Stub
  get actions(): Action[] {
    const actions = [
      {
        label: 'Send to Driver',
        id: 'send-to-driver',
        event: 'paystub:send-to-driver',
        icon: 'send',
      },
      {
        label: 'View PDF',
        id: 'view-paystub-pdf',
        event: 'paystub:view-pdf',
        icon: 'pdf',
      },
    ]
    if (this.status === 'PAID') {
      actions.push({
        label: 'Revert Paystub',
        id: 'revert-paystub',
        event: 'paystub:revert',
        icon: 'reset',
      })
    }
    return actions
  }

  close(): void {
    paystub.clear()
    sidebar.pop()
  }
}


import { Provide } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { DataTableColumn } from '@/models/DataTableColumn'
import CUCollectionTable2 from '@/components/CUCollectionTable2.vue'
import { TableAction } from '@/models/TableAction'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import { getInvoiceColumns } from '@/data/driverPayTableView'
import HoldUpModal from './HoldUpModal.vue'
import {
  fetchInvoices,
  getAllDraftInvoices,
  exportInvoices,
} from '@/services/driverPayTableViews'
import { Invoice } from '@/models/Invoice'
import ReviewInvoicesSidebar from './ReviewInvoicesSidebar.vue'
import { INVOICE_FILTER_ACTION } from '@/models/FilterActionTypes'

@Component({
  components: { CUCollectionTable2, HoldUpModal },
})
export default class DriverPaySummaryInvoiceTab extends mixins(DateMixin) {
  @Provide() limitTableToViewHeight = true
  @Provide() mobileRowBreakpoint = 1570
  loading = false
  count = 0

  exportInvoices = exportInvoices
  fetchInvoices = fetchInvoices
  invoiceColumns: DataTableColumn[] = []
  filterActionType = INVOICE_FILTER_ACTION
  selectedRows = []
  filtersLoading = false

  refreshTable(): void {
    EventBus.$emit('refresh-tableview')
  }

  openReviewInvoicesSidebar(rows: Partial<Invoice>[]): void {
    const reservationIds = [
      ...new Set(rows.map((row) => Number(row.reservationId))),
    ]
    sidebar.popAllAndPush({
      component: ReviewInvoicesSidebar,
      width: 1000,
      props: {
        reservationIds,
      },
      on: { refresh: () => EventBus.$emit('refresh-tableview') },
    })
  }

  async handleReviewDrafts(): Promise<void> {
    try {
      const draftInvoices = await getAllDraftInvoices()
      this.openReviewInvoicesSidebar(draftInvoices.data)
    } catch (e) {
      console.error(e)
    }
  }

  handleSelectedRowsUpdate(selectedRows: any[]): void {
    this.selectedRows = selectedRows
  }

  get areSelectedRowsPendingOrPaid(): boolean {
    return this.selectedRows.some(
      (row) => row.status === 'PENDING' || row.status === 'PAID'
    )
  }

  get invoiceActions(): TableAction[] {
    return [
      {
        displayText: 'Review Selected',
        key: 'review-selected',
        icon: 'eyes',
        action: this.openReviewInvoicesSidebar,
      },
      {
        displayText: 'Mark as Reviewed',
        key: 'mark-as-reviewed',
        icon: 'done',
        iconWidth: '20px',
        iconHeight: '20px',
        disabled: this.areSelectedRowsPendingOrPaid,
        action: (rows: Invoice[]): void => {
          EventBus.$emit('driver-pay:set-reviewed', rows)
        },
      },
      {
        displayText: 'Mark as Draft',
        key: 'mark-as-draft',
        icon: 'notes_alt',
        disabled: this.areSelectedRowsPendingOrPaid,
        action: (rows: Invoice[]): void => {
          EventBus.$emit('driver-pay:set-draft', rows)
        },
      },
      {
        displayText: 'Create Pay Stubs',
        key: 'create-stubs',
        icon: 'receipt_alt',
        iconWidth: '20px',
        iconHeight: '20px',
        disabled: this.areSelectedRowsPendingOrPaid,
        action: (rows: Invoice[]): void => {
          EventBus.$emit('driver-pay:create-paystubs', rows)
        },
      },
      {
        displayText: 'Generate Pay Report',
        key: 'generate-pay-report-from-invoice-ids',
        icon: 'fancy_document',
        iconWidth: '20px',
        iconHeight: '20px',
        action: (rows: Invoice[]): void => {
          EventBus.$emit(
            'driver-pay:generate-pay-report-from-invoice-ids',
            rows
          )
        },
      },
    ]
  }

  async created(): Promise<void> {
    try {
      this.filtersLoading = true
      this.invoiceColumns = await getInvoiceColumns(this.formatLongDateLongTime)
    } catch (e) {
      console.error(e)
    } finally {
      this.filtersLoading = false
    }
  }

  mounted(): void {
    EventBus.$on('driver-pay:review-drafts', this.handleReviewDrafts)
    EventBus.$on('selected-rows:update', this.handleSelectedRowsUpdate)
  }

  beforeDestroy(): void {
    EventBus.$off('driver-pay:review-drafts', this.handleReviewDrafts)
    EventBus.$off('selected-rows:update', this.handleSelectedRowsUpdate)
  }
}

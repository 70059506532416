
import {
  DriverPaymentInvoiceDetail,
  DriverPaymentCharge,
} from '@/models/DriverPay'
import { currencyFilter, percentageFormatter, pluralize } from '@/utils/string'
import dayjs from 'dayjs'
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'

@Component({})
export default class PaystubDetailSidebarChargesTable extends mixins(
  DateMixin
) {
  @Prop() private invoice!: DriverPaymentInvoiceDetail

  currencyFilter = currencyFilter

  get formattedStartDate(): string {
    return dayjs(this.invoice.start).tz('America/New_York').format('MM/DD/YYYY')
  }

  getChargeDate(charge: DriverPaymentCharge): string {
    if (charge.details?.date) {
      return this.formatLongDate(charge.details.date)
    }
    if (charge.createdOn) {
      return this.formatLongDate(charge.createdOn)
    }
    return ''
  }

  getChargeName(charge: DriverPaymentCharge): string {
    if (charge.chargeType === 'BASE_PAY') {
      if (charge.rateTypeLabel) {
        return charge.rateTypeLabel
      }
      switch (charge.details.rate) {
        case 'HOURLY':
          return 'Hourly'
        case 'DAILY':
          return 'Daily'
        case 'PERCENTAGE':
          return 'Percentage'
        case 'PER_DIEM':
          return 'Per Diem'
        case 'PER_TRIP':
          return 'Per Trip'
        case 'GRATUITY':
          return 'Gratuity'
        default:
          return 'Charge'
      }
    }

    return charge.details.name
  }

  getChargePaymentLabel(charge: DriverPaymentCharge): string {
    if (charge.chargeType !== 'BASE_PAY') {
      return currencyFilter(charge.total)
    }

    const baseRateKey = charge.details.rate
    const amount = currencyFilter(charge.details.amount)
    const count = charge.details.count

    switch (baseRateKey) {
      case 'HOURLY':
        return `${amount} x ${count} ${pluralize(count, 'hr')}`
      case 'DAILY':
        return `${amount} x ${count} ${pluralize(count, 'day')}`
      case 'PERCENTAGE':
        return `${amount} x ${percentageFormatter(count)}`
      case 'PER_DIEM':
        return `${amount} x ${count} ${pluralize(count, 'day')}`
      case 'PER_TRIP':
        return `${amount} x ${count} ${pluralize(count, 'trip')}`
      default:
        return `${amount} x ${count}`
    }
  }
}

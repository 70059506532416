import { VuexModule, Module, Action } from 'vuex-class-modules'
import store from '@/store/index'
import { DriverPaymentPaystub } from '@/models/DriverPay'

@Module({ generateMutationSetters: true })
class PaystubModule extends VuexModule {
  _paystub: DriverPaymentPaystub = {} as DriverPaymentPaystub

  get paystub(): DriverPaymentPaystub {
    return this._paystub
  }

  @Action
  setPaystub(paystub: DriverPaymentPaystub) {
    this._paystub = paystub
  }

  @Action
  markAsPaid(paidDate: string) {
    this._paystub.status = 'PAID'
    this._paystub.paidDate = paidDate
  }

  @Action
  markAsReverted() {
    this._paystub.status = 'REVERTED'
    this._paystub.paidDate = null
  }

  @Action
  clear() {
    this._paystub = {} as DriverPaymentPaystub
  }
}

export default new PaystubModule({ store, name: 'paystub' })

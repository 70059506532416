
import { Provide } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { DataTableColumn } from '@/models/DataTableColumn'
import CUCollectionTable2 from '@/components/CUCollectionTable2.vue'
import { TableAction } from '@/models/TableAction'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import { getPaystubColumns } from '@/data/driverPayTableView'
import ContactSidebarDetail from './ContactSidebarDetail.vue'
import HoldUpModal from './HoldUpModal.vue'
import reservation from '@/store/modules/reservation'
import { exportPaystubs, fetchPaystubs } from '@/services/driverPayTableViews'
import { Paystub, UpdatePaystubsBulkRequest } from '@/models/DriverPay'
import { PAYSTUB_FILTER_ACTION } from '@/models/FilterActionTypes'
import PaystubDetailSidebar from './PaystubDetailSidebar.vue'
import client from '@/services/driverpay'
import paystub from '@/store/modules/paystub'
import PaystubDetailSidebarMarkAsPaidModal from './PaystubDetailSidebarMarkAsPaidModal.vue'
import PaystubDetailSidebarRevertModal from './PaystubDetailSidebarRevertModal.vue'
import DriverPaySendToDriverSidebar from './DriverPaySendToDriverSidebar.vue'
import DriverPaySendPaymentSummarySidebar from './DriverPaySendPaymentSummarySidebar.vue'
import dayjs from 'dayjs'
import { apiBaseUrl } from '@/utils/env'
import { saveAs } from 'file-saver'
import auth from '@/store/modules/auth'
import { UserGroupIds } from '@/data/userGroups'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import { displayInLocalTimezone } from '@/utils/date'

@Component({
  components: {
    CUCollectionTable2,
    HoldUpModal,
    PaystubDetailSidebarMarkAsPaidModal,
    PaystubDetailSidebarRevertModal,
  },
})
export default class DriverPaySummaryPaystubsTab extends mixins(DateMixin) {
  @Provide() limitTableToViewHeight = true
  @Provide() mobileRowBreakpoint = 1570
  tabIndex = 0
  count = 0

  fetchPaystubs = fetchPaystubs
  exportPaystubs = exportPaystubs
  paystubColumns: DataTableColumn[] = []
  filterActionType = PAYSTUB_FILTER_ACTION
  selected: Paystub[] = []
  displayMarkAsPaidModal = false
  displayRevertModal = false
  activelySelectedRows = []
  filtersLoading = false

  get selectedUnpaid(): Paystub[] {
    return this.selected.filter((p) => p.status !== 'PAID')
  }

  get selectedReverted(): Paystub[] {
    return this.selected.filter((p) => p.status === 'REVERTED')
  }

  get selectedPaid(): Paystub[] {
    return this.selected.filter((p) => p.status === 'PAID')
  }

  openContact(row: Paystub): void {
    sidebar.popAllAndPush({
      component: ContactSidebarDetail,
      props: {
        userId: row.driver.userId,
        showContactsTVButton: false,
        simple: true,
      },
      on: { refresh: () => EventBus.$emit('refresh-tableview') },
    })
  }

  async openPaystubDetail(row: Paystub): Promise<void> {
    const res = await client.paystubById(row.id)
    paystub.setPaystub(res.data)

    sidebar.popAllAndPush({
      component: PaystubDetailSidebar,
      props: {
        paystub: row,
      },
      width: 1000,
      on: { refresh: () => EventBus.$emit('refresh-tableview') },
    })
  }

  refreshTable(): void {
    EventBus.$emit('refresh-tableview')
  }

  get paystubActions(): TableAction[] {
    const actions: TableAction[] = [
      {
        displayText: 'Mark as Paid',
        key: 'mark-as-paid',
        icon: 'order_approve',
        iconWidth: '20px',
        iconHeight: '20px',
        disabled:
          this.areActivelySelectedPaystubsReverted ||
          this.areActivelySelectedPaystubsPaid,
        action: (rows: Paystub[]): void => {
          EventBus.$emit('driver-pay:mark-as-paid', rows)
        },
      },
      {
        displayText: 'Revert Pay Stubs',
        key: 'revery-pay-stubs',
        icon: 'return',
        iconWidth: '20px',
        iconHeight: '20px',
        disabled: this.areActivelySelectedPaystubsReverted,
        action: (rows: Paystub[]): void => {
          EventBus.$emit('driver-pay:revert-pay-stubs', rows)
        },
      },
      {
        displayText: 'Send to Driver',
        key: 'send-to-driver',
        icon: 'send_alt',
        action: (rows: Paystub[]): void => {
          EventBus.$emit('driver-pay:send-to-driver', rows)
        },
        disabled: (rows: Paystub[]): boolean => {
          return rows.length > 1
        },
      },
      // Commenting out for v1 launch
      // {
      //   displayText: 'Send Payment Summary',
      //   key: 'send-payment-summary',
      //   icon: 'send',
      //   action: (rows: Paystub[]): void => {
      //     EventBus.$emit('driver-pay:send-payment-summary', rows)
      //   },
      // },
    ]
    if (this.isAdmin() || this.hasOperationsPrivileges()) {
      actions.push(
        {
          displayText: 'Individual PDFS',
          key: 'download-multiple-pdfs',
          icon: 'download',
          action: (rows: Paystub[]): void => {
            EventBus.$emit('driver-pay:download-multiple-paystub-pdfs', rows)
          },
        },
        {
          displayText: 'Combined PDF',
          key: 'download-bulk-pdf',
          icon: 'download',
          action: (rows: Paystub[]): void => {
            EventBus.$emit('driver-pay:download-bulk-paystub-pdf', rows)
          },
          disabled: (rows: Paystub[]): boolean => {
            return rows.length < 2
          },
        }
      )
    }
    return actions
  }

  markAsPaidBulk(rows: Paystub[]): void {
    this.selected = rows
    this.displayMarkAsPaidModal = true
  }

  async handleMarkAsPaid(paidOn: string): Promise<void> {
    const payStubIds = this.selected.map((p) => p.id)
    paidOn = dayjs(paidOn).toISOString()
    const payload: UpdatePaystubsBulkRequest = {
      payStubIds,
      paidOn,
      status: 'PAID',
    }

    try {
      const res = await client.updatePaystubsBulk(payload)
      if (res.status === 200) {
        EventBus.$emit('refresh-tableview')
      }
    } catch (err) {
      console.log(err)
      EventBus.$emit('snackbar:error', 'Error updating paystubs')
    } finally {
      this.selected = []
      this.displayMarkAsPaidModal = false
    }
  }

  isAdmin(): boolean {
    return auth.getUser?.groupId === UserGroupIds.ADMIN
  }

  hasOperationsPrivileges(): boolean {
    return auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.OPERATIONS)
  }

  revertPayStubsBulk(rows: Paystub[]): void {
    this.displayRevertModal = true
    this.selected = rows
  }

  async handleRevert(): Promise<void> {
    const payStubIds = this.selected.map((p) => p.id)
    const payload: UpdatePaystubsBulkRequest = {
      payStubIds,
      status: 'REVERTED',
    }

    try {
      const res = await client.updatePaystubsBulk(payload)
      if (res.status === 200) {
        EventBus.$emit('refresh-tableview')
      }
    } catch (err) {
      console.log(err)
      EventBus.$emit('snackbar:error', 'Error updating paystubs')
    } finally {
      this.selected = []
      this.displayRevertModal = false
    }
  }

  sendToDriverBulk(rows: Paystub[]): void {
    // Make sure paystubs all belong to the same driver
    const userIds = rows.map((r) => r.driver.userId)
    const uniqueUserIds = [...new Set(userIds)]
    if (uniqueUserIds.length > 1) {
      EventBus.$emit(
        'snackbar:error',
        'Cannot send paystubs to multiple drivers'
      )
      return
    }
    const driver = { ...rows[0].driver, type: 'Driver' }

    sidebar.push({
      component: DriverPaySendToDriverSidebar,
      props: {
        paystubs: rows,
        recipients: [driver],
      },
      title: 'Send Pay Stub',
    })
  }

  handleSelectedRowsUpdate(selectedRows: any[]): void {
    this.activelySelectedRows = selectedRows
  }

  downloadBulkPaystubPdf(rows: Paystub[]): void {
    const payStubIds = rows.map((r) => r.id)
    const tz = auth?.getUser?.timeZone || dayjs.tz.guess()
    const name = `Bulk_Paystub_Export_${displayInLocalTimezone(
      dayjs().tz(tz).toISOString()
    )}.pdf`
    client.downloadPayStub(payStubIds).then((res) => saveAs(res.data, name))
  }

  downloadMultiplePaystubPdfs(rows: Paystub[]): void {
    for (const row of rows) {
      const name = `${row.label}_Paystub.pdf`
      client.downloadPayStub([row.id]).then((res) => saveAs(res.data, name))
    }
  }

  get areActivelySelectedPaystubsReverted(): boolean {
    return this.activelySelectedRows.some((row) => row.status === 'REVERTED')
  }

  get areActivelySelectedPaystubsPaid(): boolean {
    return this.activelySelectedRows.some((row) => row.status === 'PAID')
  }

  sendPaymentSummary(rows: Paystub[]): void {
    sidebar.push({
      component: DriverPaySendPaymentSummarySidebar,
      props: {
        paystubs: rows,
      },
      title: 'Send Summary',
    })
  }

  async created(): Promise<void> {
    try {
      this.filtersLoading = true
      this.paystubColumns = await getPaystubColumns(this.formatLongDate)
    } catch (err) {
      console.log(err)
    } finally {
      this.filtersLoading = false
    }
  }

  mounted(): void {
    EventBus.$on('driver-pay:open-contact', this.openContact)
    EventBus.$on('paystub:open-detail', this.openPaystubDetail)
    EventBus.$on('driver-pay:mark-as-paid', this.markAsPaidBulk)
    EventBus.$on('driver-pay:revert-pay-stubs', this.revertPayStubsBulk)
    EventBus.$on('driver-pay:send-to-driver', this.sendToDriverBulk)
    EventBus.$on(
      'driver-pay:download-bulk-paystub-pdf',
      this.downloadBulkPaystubPdf
    )
    EventBus.$on(
      'driver-pay:download-multiple-paystub-pdfs',
      this.downloadMultiplePaystubPdfs
    )
    EventBus.$on('driver-pay:send-payment-summary', this.sendPaymentSummary)
    EventBus.$on('selected-rows:update', this.handleSelectedRowsUpdate)
  }

  beforeDestroy(): void {
    EventBus.$off('driver-pay:open-contact', this.openContact)
    EventBus.$off('paystub:open-detail', this.openPaystubDetail)
    EventBus.$off('driver-pay:mark-as-paid', this.markAsPaidBulk)
    EventBus.$off('driver-pay:revert-pay-stubs', this.revertPayStubsBulk)
    EventBus.$off('driver-pay:send-to-driver', this.sendToDriverBulk)
    EventBus.$off(
      'driver-pay:download-bulk-paystub-pdf',
      this.downloadBulkPaystubPdf
    )
    EventBus.$off(
      'driver-pay:download-multiple-paystub-pdfs',
      this.downloadMultiplePaystubPdfs
    )
    EventBus.$off('driver-pay:send-payment-summary', this.sendPaymentSummary)
    EventBus.$off('selected-rows:update', this.handleSelectedRowsUpdate)
  }

  destroyed(): void {
    reservation.clear()
  }
}

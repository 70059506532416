import { DataTableColumn } from '@/models/DataTableColumn'
import ResListResId from '@/components/ResListResId.vue'
import { Reservation } from '@/models/dto/Reservation'
import { RawLocation } from 'vue-router'
import { currencyFilter, toTitle } from '@/utils/string'
import { displayDateRangeWithDayOfWeek } from '@/utils/date'
import DriverPayStatusTag from '@/components/DriverPayStatusTag.vue'
import auth from '@/store/modules/auth'
import {
  CURRENCY_COLUMN_WIDTH,
  DATE_COLUMN_WIDTH,
  ID_COLUMN_WIDTH,
  NAME_COLUMN_WIDTH,
  STATUS_COLUMN_WIDTH,
} from './tableViews'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import { Invoice } from '@/models/Invoice'
import { DateOptions } from '@/models/Date'
import driver from '@/services/driver'
import { Driver } from '@/models/dto/Driver'
const CAN_VIEW_CONTACTS = auth.getUserRoleNames.includes(
  ACCESS_SETTINGS_ROLES.CONTACTS
)

const getDriverFilterViewDropDownItems = async () => {
  try {
    const driversResponse = await driver.tableView({ pageSize: -1 })

    const sortedDriverList = (driversResponse.data.resultList as Driver[]).sort(
      (a: Driver, b: Driver) => {
        if (a.driverActivated && !b.driverActivated) {
          return -1
        } else if (!a.driverActivated && b.driverActivated) {
          return 1
        }
        return (
          a.firstName.localeCompare(b.firstName) ||
          a.lastName.localeCompare(b.lastName)
        )
      }
    )

    const driverItems = sortedDriverList.map((driver) => ({
      text: `${driver.firstName} ${driver.lastName}`.trim(),
      value: driver.userId,
      tag: driver.driverActivated ? undefined : 'Inactive',
      tagBorder: driver.driverActivated ? undefined : 'gray-text-dark',
      tagBackground: driver.driverActivated ? undefined : 'bg-gray-2',
    }))

    return driverItems
  } catch (error) {
    console.error(error)
    return []
  }
}

export const getInvoiceColumns = async (
  formatLongDateLongTime: (dateString: string, opts: DateOptions) => string
): Promise<DataTableColumn[]> => {
  const driverFilterItems = await getDriverFilterViewDropDownItems()

  return [
    {
      _t_id: '2d70234a-51a6-483e-8e3d-c89d2402841f',
      text: 'Res. ID',
      value: 'managedId',
      type: 'slot',
      columnSlotComponent: ResListResId,
      sortable: false,
      columnSlotProps: (row: Invoice): Record<string, unknown> => ({
        displayUnread: false,
        reservationId: row.reservationId,
        managedId: row.reservationId,
        showPreview: false,
      }),
      linkLocation: (row: Reservation): RawLocation => ({
        name: 'reservations.detail',
        params: { id: String(row.reservationId) },
      }),
      linkToNewTab: true,
      hidden: false,
      required: true,
      filterProp: 'managedId',
      filterBySearch: true,
      filterable: true,
      width: ID_COLUMN_WIDTH,
    },
    {
      _t_id: 'bc3784e5-6be1-4535-8104-c35431c22e32',
      text: 'Res. Status',
      value: 'reservation.reservationStatusKey',
      computedText: (row): string =>
        toTitle(row.reservation?.reservationStatusKey),
      hidden: false,
      sortable: false,
    },
    {
      _t_id: '8435e3d9-23ae-40a8-8c48-57c66f2ae1ea',
      text: 'Driver',
      value: 'driverName',
      sortable: true,
      sortProp: 'driverName',
      computedText: (row): string =>
        `${row.driver.name}${
          row.duplicateIndex ? ` (${row.duplicateIndex})` : ''
        }`,
      hidden: false,
      unsetWidthOnBreakpoint: 'xlOnly',
      filterBySearch: false,
      filterable: true,
      filterProp: 'driverIds',
      filterInterface: 'multi-select-search',
      filterType: 'eq',
      filterDropdownItems: driverFilterItems,
      omitExport: false,
      width: NAME_COLUMN_WIDTH,
    },
    {
      _t_id: 'e7701736-b904-4ede-8d55-9373e7abab78',
      text: 'Status',
      value: 'status',
      columnSlotComponent: DriverPayStatusTag,
      columnSlotProps: (row: Invoice) => ({
        status: row.status,
      }),
      hidden: false,
      filterable: true,
      filterInterface: 'multi-select',
      filterDropdownItems: [
        { text: 'Draft', value: 'DRAFT' },
        { text: 'Reviewed', value: 'REVIEWED' },
        { text: 'Pending', value: 'PENDING' },
        { text: 'Paid', value: 'PAID' },
      ],
      filterValue: ['DRAFT', 'REVIEWED'],
      filterProp: 'status',
      sortable: false,
      filterType: 'eq',
      width: STATUS_COLUMN_WIDTH,
    },
    {
      _t_id: '5a12bcd3-1d2e-4462-aec1-cc328a10b13c',
      text: 'Total Pay',
      value: 'totalCharges',
      sortable: true,
      computedText: (row): string => currencyFilter(row.totalCharges),
      hidden: false,
      width: CURRENCY_COLUMN_WIDTH,
    },
    {
      _t_id: '81765aa3-dbbd-4655-9bb3-69e4ce17b138',
      text: 'Start',
      value: 'start',
      computedText: (row): string =>
        formatLongDateLongTime(row.start, {
          tz: row.startTimezone,
          showTimezone: true,
          showDot: true,
          showMeridianUpper: true,
        }) || '',
      hidden: false,
      sortable: true,
      filterable: true,
      filterInterface: 'date-range',
      filterProp: 'start',
      filterValue: [],
      filterHiddenOnTopBar: false,
      width: DATE_COLUMN_WIDTH,
    },
    {
      _t_id: '2f2a2d22-57fa-4033-a133-67e3675fd58c',
      text: 'End',
      value: 'stop',
      computedText: (row): string =>
        formatLongDateLongTime(row.stop, {
          tz: row.stopTimezone,
          showTimezone: true,
          showDot: true,
          showMeridianUpper: true,
        }) || '',
      hidden: false,
      sortable: true,
      filterable: false,
      filterHiddenOnTopBar: false,
      width: DATE_COLUMN_WIDTH,
    },
    {
      _t_id: '81d36464-ef9f-4af8-bbd1-9c13a9c92433',
      text: 'Company',
      value: 'companyName',
      sortable: true,
      computedText: (row: Invoice): string => row.company?.name,
      type: CAN_VIEW_CONTACTS ? 'link' : 'text',
      clickEvent: 'driver-pay:open-company',
      hidden: false,
      unsetWidthOnBreakpoint: 'xlOnly',
      width: NAME_COLUMN_WIDTH,
    },
    {
      _t_id: '24d55b6c-c683-4e56-b0ba-f1e0f5901589',
      text: 'Booking Contact',
      value: 'bookingContactName',
      sortable: true,
      computedText: (row: Invoice): string => row.bookingContact?.name || '',
      type: CAN_VIEW_CONTACTS ? 'link' : 'text',
      clickEvent: 'driver-pay:open-contact',
      hidden: false,
      unsetWidthOnBreakpoint: 'xlOnly',
      width: NAME_COLUMN_WIDTH,
    },
  ]
}

export const getPaystubColumns = async (
  formatLongDate: (dateString: string, opts?: DateOptions) => string
): Promise<DataTableColumn[]> => {
  const driverFilterItems = await getDriverFilterViewDropDownItems()

  return [
    {
      _t_id: '0f72b0d0-4feb-494a-bf17-c9f5bd4a8059',
      text: 'Pay Stub',
      value: 'label',
      type: 'link',
      hidden: false,
      required: true,
      clickEvent: 'paystub:open-detail',
      filterProp: 'label',
      filterBySearch: true,
      filterValue: null,
      filterable: true,
      width: NAME_COLUMN_WIDTH,
    },
    {
      _t_id: '4f2838a0-c8b2-401e-a475-ee0c778a37ea',
      text: 'Status',
      value: 'status',
      columnSlotComponent: DriverPayStatusTag,
      columnSlotProps: (row: Invoice) => ({
        status: row.status,
      }),
      hidden: false,
      filterable: false,
      sortable: false,
      width: STATUS_COLUMN_WIDTH,
    },
    {
      _t_id: 'd50604b3-ce60-4f56-8344-26aeb869cc3f',
      text: 'Driver',
      value: 'driverName',
      sortable: true,
      sortProp: 'driverName',
      computedText: (row): string => row.driver.name,
      hidden: false,
      unsetWidthOnBreakpoint: 'xlOnly',
      filterBySearch: false,
      filterable: true,
      filterProp: 'driverIds',
      filterInterface: 'multi-select-search',
      filterType: 'eq',
      filterDropdownItems: driverFilterItems,
      filterValue: [],
      omitExport: false,
      width: NAME_COLUMN_WIDTH,
    },
    {
      _t_id: '6a204f0e-4d92-42c6-b934-a1b03b7b1ef4',
      text: 'Reservation ID',
      computedText: (row): string =>
        (row.reservationManagedIds || []).join(', '),
      value: 'reservationManagedIds',
      filterValue: null,
      hidden: false,
      width: ID_COLUMN_WIDTH,
    },
    {
      _t_id: '75b6450f-b22b-408d-8093-dd3446ddb23b',
      text: 'Total Pay',
      value: 'total',
      computedText: (row): string => currencyFilter(row.total),
      hidden: false,
      width: CURRENCY_COLUMN_WIDTH,
    },
    {
      _t_id: '3b45d080-345b-407f-b809-3ede47dbba05',
      text: 'Payment Date',
      value: 'paidDate',
      computedText: (row): string =>
        row.paidDate ? formatLongDate(row.paidDate) : null,
      hidden: false,
      filterable: true,
      filterInterface: 'date-range',
      filterProp: 'paidDate',
      filterValue: [],
      filterHiddenOnTopBar: false,
      width: DATE_COLUMN_WIDTH,
    },
    {
      _t_id: '105928d4-55a4-4b34-a077-0354c34031b4',
      text: 'Pay Range',
      value: 'payPeriod',
      computedText: (row): string =>
        row.earliestPickupTimestamp && row.latestDropoffTimestamp
          ? displayDateRangeWithDayOfWeek(
              row.earliestPickupTimestamp,
              row.latestDropoffTimestamp
            )
          : '',
      hidden: false,
      filterable: false,
      width: DATE_COLUMN_WIDTH,
    },
  ]
}


import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  ChargeTypes,
  ChargeSuffixes,
  DriverPaymentPaystub,
  DriverPaymentCharge,
  AggregatedChargeInfo,
  RateType,
} from '@/models/DriverPay'
import { currencyFilter } from '@/utils/string'
import vehicleService from '@/services/vehicle'
import driverPay from '@/services/driverpay'
import auth from '@/store/modules/auth'

@Component({})
export default class PayStubDetailSidebarRatesFooter extends Vue {
  @Prop({ required: true }) paystub!: DriverPaymentPaystub

  currencyFilter = currencyFilter
  charges: AggregatedChargeInfo[] = []
  rateTypes: RateType[] = []

  get invoiceCharges(): AggregatedChargeInfo[] {
    const sortedCharges = [...this.charges].sort((a, b) => {
      if (a.rate === ChargeTypes.REIMBURSEMENT) {
        return 1
      }
      if (b.rate === ChargeTypes.REIMBURSEMENT) {
        return -1
      }
      if (a.rate === ChargeTypes.GRATUITY) {
        return 1
      }
      if (b.rate === ChargeTypes.GRATUITY) {
        return -1
      }
      if (a.rate === ChargeTypes.PER_DIEM) {
        return 1
      }
      if (b.rate === ChargeTypes.PER_DIEM) {
        return -1
      }
      return 0
    })
    return sortedCharges
  }

  isPerDiem(rate: string): boolean {
    return rate === ChargeTypes.PER_DIEM
  }

  isReimbursement(rate: string): boolean {
    return rate === ChargeTypes.REIMBURSEMENT
  }

  isGratuity(rate: string): boolean {
    return rate === ChargeTypes.GRATUITY
  }

  isPercentage(rate: string): boolean {
    return rate === ChargeTypes.PERCENTAGE
  }

  isSimpleRate(rate: string): boolean {
    return (
      !this.isPerDiem(rate) &&
      !this.isReimbursement(rate) &&
      !this.isGratuity(rate)
    )
  }

  isComplexRate(rate: string): boolean {
    return !this.isSimpleRate(rate)
  }

  formatRateName(
    rate: string,
    vehicleTypeName: string,
    rateTypeLabel?: string
  ): string {
    if (rateTypeLabel) {
      return rateTypeLabel
    }
    if (this.isComplexRate(rate)) {
      return rate
        .toLowerCase()
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    }
    return vehicleTypeName
  }

  getSuffixFromRate(rate: string, pluraize = false): string {
    switch (rate) {
      case ChargeTypes.PER_DIEM:
        return ` ${ChargeSuffixes.PER_DIEM}${pluraize ? 's' : ''}`
      case ChargeTypes.DAILY:
        return ` ${ChargeSuffixes.DAILY}${pluraize ? 's' : ''}`
      case ChargeTypes.HOURLY:
        return ` ${ChargeSuffixes.HOURLY}${pluraize ? 's' : ''}`
      case ChargeTypes.PERCENTAGE:
        return ChargeSuffixes.PERCENTAGE
      case ChargeTypes.PER_TRIP:
        return ` ${ChargeSuffixes.PER_TRIP}${pluraize ? 's' : ''}`
      default:
        return ''
    }
  }

  async getVehicleTypeName(vehicleId: number): Promise<string> {
    const UNKNOWN_VEHICLE_NAME = 'Unknown Vehicle'
    try {
      const { data } = await vehicleService.byId(vehicleId)
      return data?.vehicleTypeName || UNKNOWN_VEHICLE_NAME
    } catch (error) {
      return UNKNOWN_VEHICLE_NAME
    }
  }

  async getAllVehicleDetailsFromInvoices(): Promise<{ id: string }> {
    const uniqueVehicleIds = this.paystub.invoices
      .map((invoice) => invoice.vehicle.id)
      .filter((vehicleId) => !!vehicleId)
      .filter((vehicleId, index, self) => self.indexOf(vehicleId) === index)

    const vehicleInfo: { id: string } = { id: '' }
    const vehiclePromises = uniqueVehicleIds.map((vehicleId) =>
      this.getVehicleTypeName(vehicleId)
    )

    const vehicleDetails = await Promise.all(vehiclePromises)
    for (let i = 0; i < uniqueVehicleIds.length; i++) {
      vehicleInfo[uniqueVehicleIds[i]] = vehicleDetails[i]
    }

    return vehicleInfo
  }

  getKeyFromCharge(
    charge: DriverPaymentCharge,
    vehicleTypeName: string,
    rate: string
  ): string {
    if (this.isComplexRate(rate)) {
      return charge.details.rateTypeLabel || rate
    } else if (this.isPercentage(rate)) {
      return `${vehicleTypeName}-${rate}-${charge.details.count}`
    } else {
      return `${vehicleTypeName}-${rate}-${charge.details.amount}`
    }
  }
  getRateFromCharge(charge: DriverPaymentCharge): string {
    return this.isReimbursement(charge.chargeType)
      ? charge.chargeType
      : charge.details.rate
  }

  async fetchRateTypes(): Promise<void> {
    try {
      const response = await driverPay.getCompanyRateTypes()
      this.rateTypes = response.data.sort((a, b) => a.orderIndex - b.orderIndex)
    } catch (error) {
      console.error('Error fetching rate types:', error)
    }
  }

  createAggregatedCharge(
    charge: DriverPaymentCharge,
    rate: string,
    vehicleTypeName: string
  ): AggregatedChargeInfo {
    return {
      vehicleTypeName,
      rate,
      total: charge.total,
      count: charge.details.count,
      amount: charge.details.amount,
      suffix: this.getSuffixFromRate(rate, charge.details.count > 1),
      rateTypeLabel: charge.details.rateTypeLabel,
    }
  }

  updateAggregatedCharge(
    existingAggregatedCharge: AggregatedChargeInfo,
    charge: DriverPaymentCharge,
    rate: string
  ): AggregatedChargeInfo {
    if (!this.isPercentage(rate)) {
      existingAggregatedCharge.count += charge.details.count
    } else {
      existingAggregatedCharge.amount += charge.details.amount
    }

    existingAggregatedCharge.suffix = this.getSuffixFromRate(
      charge.details.rate,
      existingAggregatedCharge.count > 1
    )
    existingAggregatedCharge.total += charge.total

    return existingAggregatedCharge
  }

  async computeAggregatedCharges(): Promise<void> {
    const mappedCharges = {}
    const vehicleInfo = await this.getAllVehicleDetailsFromInvoices()
    for (const invoice of this.paystub.invoices) {
      const vehicleTypeName = vehicleInfo[invoice.vehicle.id]
      for (const charge of invoice.charges) {
        const rate = this.getRateFromCharge(charge)
        const key = this.getKeyFromCharge(charge, vehicleTypeName, rate)

        if (mappedCharges[key]) {
          mappedCharges[key] = this.updateAggregatedCharge(
            mappedCharges[key],
            charge,
            rate
          )
        } else {
          mappedCharges[key] = this.createAggregatedCharge(
            charge,
            rate,
            vehicleTypeName
          )
        }
      }
    }

    for (const key in mappedCharges) {
      if (mappedCharges.hasOwnProperty(key)) {
        const charge = mappedCharges[key]
        this.charges.push(charge)
      }
    }
  }

  async created(): Promise<void> {
    await this.computeAggregatedCharges()
  }
}


import { Vue, Component, Prop } from 'vue-property-decorator'
import { DriverPayStatus } from '@/models/dto/Status'
import { InvoiceStatus } from '@/models/Invoice'
import { PaystubStatus } from '@/models/DriverPay'

@Component({})
export default class DriverPayStatusTag extends Vue {
  @Prop({ required: true }) readonly status!: InvoiceStatus | PaystubStatus

  get borderColor(): string {
    return DriverPayStatus.color(this.status)?.foreground
  }

  get bgColor(): string {
    return DriverPayStatus.color(this.status)?.background
  }

  get tag(): string {
    const label = this.status || ''
    return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase()
  }
}


import { DriverPaymentInvoiceDetail } from '@/models/DriverPay'
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { currencyFilter } from '@/utils/string'

@Component({})
export default class PaystubDetailSidebarReservationTable extends mixins(
  DateMixin
) {
  @Prop() private invoice!: DriverPaymentInvoiceDetail

  currencyFilter = currencyFilter

  get bookingContactName(): string {
    return this.invoice.bookingContact?.name || '--'
  }

  get formattedStartDate(): string {
    if (!this.invoice.start) {
      return ''
    }
    return this.formatLongDateLongTime(String(this.invoice.start), {
      tz: this.invoice.startTimezone,
      showMeridianUpper: true,
      showTimezone: true,
    })
  }

  get formattedStopDate(): string {
    if (!this.invoice.stop) {
      return ''
    }

    return this.formatLongDateLongTime(String(this.invoice.stop), {
      tz: this.invoice.stopTimezone,
      showMeridianUpper: true,
      showTimezone: true,
    })
  }
}


import { Reservation } from '@/models/dto'
import reservationClient from '@/services/reservation'
import assignmentClient from '@/services/tripAssignment'
import tripClient from '@/services/trip'
import sidebar from '@/store/modules/sidebar'
import { isoToJSDate } from '@/utils/date'
import { Prop, Watch } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import CUSkeletonLoader from './CUSkeletonLoader.vue'
import reservationStore from '@/store/modules/reservation'
import ReservationDriverPayDriverDetail from './ReservationDriverPayDriverDetail.vue'
import ReservationDriverPayStats from '@/components/ReservationDriverPayStats.vue'
import { getReservationInformation } from '@/utils/reservation'
import { openInNewTab } from '@/utils/router'

@Component({
  components: {
    CUSkeletonLoader,
    ReservationDriverPayDriverDetail,
    ReservationDriverPayStats,
  },
})
export default class ReviewInvoicesSidebar extends mixins(DateMixin) {
  @Prop() reservationIds!: number[]
  currentReservationIndex = 0
  currentReservation: Reservation | null = null
  loading = false
  state = reservationStore

  get getCurrentReservationManagedId(): string {
    if (
      this.state.reservation?.reservationId ===
      this.reservationIds[this.currentReservationIndex]
    ) {
      return this.state.reservation.managedId
    }
    return ''
  }

  get getCurrentReservationIndex(): number {
    return this.currentReservationIndex + 1
  }

  get customerName(): string {
    return this.currentReservation?.customerName || ''
  }

  get companyName(): string {
    return this.currentReservation?.customerAccountName || ''
  }

  get tripType(): string {
    return this.currentReservation?.tripType?.label || ''
  }

  get passengerCount(): number {
    return this.currentReservation?.passengerCount || 0
  }

  get assignedDrivers(): number {
    return (
      this.state?.assignments?.flatMap((a) => a.driverAssignments)?.length || 0
    )
  }

  get pickupLocation(): string {
    return this.currentReservation?.stops[0]?.address?.name || ''
  }

  get pickupDate(): string {
    if (this.loading && this.currentReservation?.startDate) {
      return ''
    }

    return this.currentReservation && this.currentReservation?.startDate
      ? this.formatLongDateShortTime(
          this.currentReservation.startDate.toString(),
          {
            showMeridianUpper: true,
            showTimezone: true,
          }
        )
      : ''
  }

  get dropoffDate(): string {
    if (this.loading) {
      return ''
    }

    return this.currentReservation && this.currentReservation?.stops
      ? this.formatLongDateShortTime(
          this.currentReservation.stops[
            this.currentReservation.stops.length - 1
          ].dropoffDatetime.toString(),
          {
            showTimezone: true,
            showMeridianUpper: true,
          }
        )
      : ''
  }

  handleReservationIdClick(): void {
    openInNewTab({
      name: 'reservations.detail',
      params: {
        id: `${this.currentReservation.reservationId}`,
      },
    })
  }

  formatDateDifference(date1: Date, date2: Date): string {
    const diffInMs = Math.abs(date1.getTime() - date2.getTime())
    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )

    if (days === 0) {
      return `~${hours} hours`
    } else if (hours === 0) {
      return `~${days} days`
    } else {
      return `~${days} days and ${hours} hours`
    }
  }

  get tripDuration(): string {
    if (this.loading) {
      return ''
    }

    return this.currentReservation &&
      this.currentReservation?.startDate &&
      this.currentReservation?.stops
      ? this.formatDateDifference(
          isoToJSDate(this.currentReservation.startDate.toString()),
          isoToJSDate(
            this.currentReservation.stops[
              this.currentReservation.stops.length - 1
            ].dropoffDatetime
          )
        )
      : ''
  }

  @Watch('currentReservationIndex', { immediate: true })
  async onCurrentReservationIndexChange(): Promise<void> {
    try {
      this.loading = true
      const reservationId = this.reservationIds[this.currentReservationIndex]
      const [reservationResponse, assignmentReponse] = await Promise.all([
        reservationClient.byId(reservationId),
        assignmentClient.byReservationIds([reservationId]),
      ])

      this.state.setReservation(reservationResponse.data)
      this.state.setAssignments(assignmentReponse.data.vehicleAssignments)

      const tripId = this.state.reservation?.tripId
      const [tripReponse] = await Promise.all([
        tripClient.byId(tripId),
        this.state.fetchInvoices(),
      ])
      this.state.setTrip(tripReponse.data.trip)

      this.currentReservation = reservationResponse.data
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }
  }

  close(): void {
    sidebar.close()
  }

  decrementCurrentReservationIndex(): void {
    if (this.currentReservationIndex > 0) {
      this.currentReservationIndex--
    }
  }

  incrementCurrentReservationIndex(): void {
    if (this.currentReservationIndex < this.reservationIds.length - 1) {
      this.currentReservationIndex++
    }
  }
}


import { DRAFT, Invoice, REVIEWED } from '@/models/Invoice'
import driverPay from '@/services/driverpay'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import { Vue, Component } from 'vue-property-decorator'
import CompanySidebarDetail from './CompanySidebarDetail.vue'
import ContactSidebarDetail from './ContactSidebarDetail.vue'
import DriverPaySummaryInvoiceTab from './DriverPaySummaryInvoiceTab.vue'
import DriverPaySummaryPaystubTab from './DriverPaySummaryPaystubTab.vue'
import HoldUpModal from '@/components/HoldUpModal.vue'
import CUSkeletonLoader from './CUSkeletonLoader.vue'
import { Tab } from '@/models/dto/Tab'
import pluralize from 'pluralize'
import { apiBaseUrl } from '@/utils/env'
import auth from '@/store/modules/auth'

@Component({
  components: {
    DriverPaySummaryInvoiceTab,
    DriverPaySummaryPaystubTab,
    CompanySidebarDetail,
    ContactSidebarDetail,
    CUSkeletonLoader,
    HoldUpModal,
  },
})
export default class DriverPaySummary extends Vue {
  tabIndex = 0
  paystubCount = 0
  invoiceCount = 0
  loading = true
  showHoldupModal = false
  selectedRows: Invoice[] = []
  modalText = ''
  modalAction = undefined

  get tabs(): Tab[] {
    return [
      {
        label: 'Reservations',
        component: DriverPaySummaryInvoiceTab,
        hash: 'reservations',
        id: 'driver-pay-reservations',
        on: {
          'update:count': (e) => {
            this.invoiceCount = e
          },
        },
      },
      {
        label: 'Pay Stubs',
        component: DriverPaySummaryPaystubTab,
        hash: 'pay-stubs',
        id: 'driver-pay-pay-stubs',
        on: {
          'update:count': (e) => {
            this.paystubCount = e
          },
        },
      },
    ]
  }

  get count(): number {
    return this.tabIndex[0] === 0 ? this.invoiceCount : this.paystubCount
  }

  onTabChange(index: number): void {
    this.tabIndex = index

    // Dynamically set metaInfo title to update the tab title
    const titleEl = document.querySelector('head title')
    titleEl.textContent = `Driver Pay - ${this.tabs[this.tabIndex].label}`

    EventBus.$emit('refresh-filters', this.tabs[index].label)
  }

  get isShowingHoldupModal(): boolean {
    return this.showHoldupModal
  }

  get holdupModalText(): string {
    return this.modalText
  }

  onHoldUpConfirmed(): void {
    if (this.modalAction) {
      this.modalAction()
    }
  }

  updateLoadingState(loading: boolean): void {
    this.loading = loading
  }

  setHoldupModalState(rows: Invoice[], text: string, action: () => void): void {
    this.modalText = text
    this.selectedRows = rows
    this.modalAction = action
    this.showHoldupModal = true
  }

  handleMarkAsReviewed(rows: Invoice[]): void {
    this.setHoldupModalState(
      rows,
      'Are you sure you want to mark these invoices as reviewed?',
      this.markAsReviewed
    )
  }

  async markAsReviewed(rows?: Invoice[]): Promise<void> {
    if (!rows) {
      rows = this.selectedRows
    }

    const invoiceIds = rows.map((r) => r.id)
    try {
      await driverPay.updateInvoiceStatuses(invoiceIds, REVIEWED)
      this.selectedRows = []
      EventBus.$emit('refresh-tableview')

      EventBus.$emit(
        'snackbar:success',
        `Successfully marked ${invoiceIds.length} invoices as reviewed`
      )
    } catch (e) {
      console.error(e)
      EventBus.$emit('snackbar:error', 'Error marking invoices as reviewed')
    }
  }

  handleMarkAsDraft(rows: Invoice[]): void {
    this.setHoldupModalState(
      rows,
      'Are you sure you want to mark these invoices as draft?',
      this.markAsDraft
    )
  }

  async markAsDraft(rows?: Invoice[]): Promise<void> {
    if (!rows) {
      rows = this.selectedRows
    }

    const invoiceIds = rows.map((r) => r.id)
    try {
      await driverPay.updateInvoiceStatuses(invoiceIds, DRAFT)
      this.selectedRows = []
      EventBus.$emit('refresh-tableview')

      EventBus.$emit(
        'snackbar:success',
        `Successfully marked ${invoiceIds.length} invoices as draft`
      )
    } catch (e) {
      console.error(e)
      EventBus.$emit('snackbar:error', 'Error marking invoices as draft')
    }
  }

  handleCreatePayStubs(rows: Invoice[]): void {
    this.setHoldupModalState(
      rows,
      'Are you sure you want to create pay stubs for these reservations and drivers?',
      this.createPayStubs
    )
  }

  generatePayReportFromInvoiceIds(rows: Invoice[]): void {
    const invoiceIds = rows.map((r) => r.id)
    const idsString = invoiceIds.join(',')
    const url = `https://${apiBaseUrl(
      'pdf'
    )}/pdf/driver-pay-report?invoiceIds=${idsString}&companyId=${
      auth.getCompanyId
    }`
    window.open(url, '_blank').focus()
  }

  async createPayStubs(rows?: Invoice[]): Promise<void> {
    if (!rows) {
      rows = this.selectedRows
    }

    const uniqueDriverIds = new Set(rows.map((row) => row.driver.userId))
    const paystubCount = uniqueDriverIds.size

    const invoiceIds = rows.map((r) => r.id)
    try {
      await driverPay.createPayStubs(invoiceIds)
      this.selectedRows = []
      EventBus.$emit('refresh-tableview')

      EventBus.$emit(
        'snackbar:success',
        `Successfully created ${paystubCount} ${pluralize(
          'paystub',
          paystubCount
        )}`
      )
    } catch (e) {
      console.error(e)

      EventBus.$emit('snackbar:error', 'Error creating paystubs')
    }
  }

  openContact(row: Invoice): void {
    sidebar.popAllAndPush({
      component: ContactSidebarDetail,
      props: {
        userId: row.bookingContact.userId,
        showContactsTVButton: false,
        simple: true,
      },
      on: { refresh: () => EventBus.$emit('refresh-tableview') },
    })
  }

  openCompany(row: Invoice): void {
    sidebar.popAllAndPush({
      component: CompanySidebarDetail,
      props: {
        customerAccountId: row.company.customerAccountId,
      },
      on: { refresh: () => EventBus.$emit('driver-pay:open-company') },
    })
  }

  updateCount(count: number): void {
    this.loading = false
  }

  mounted(): void {
    EventBus.$on('update:count', this.updateCount)
    EventBus.$on('driver-pay:set-reviewed', this.handleMarkAsReviewed)
    EventBus.$on('driver-pay:set-draft', this.handleMarkAsDraft)
    EventBus.$on('driver-pay:create-paystubs', this.handleCreatePayStubs)
    EventBus.$on(
      'driver-pay:generate-pay-report-from-invoice-ids',
      this.generatePayReportFromInvoiceIds
    )
    EventBus.$on('driver-pay:open-contact', this.openContact)
    EventBus.$on('driver-pay:open-company', this.openCompany)
  }

  beforeDestroy(): void {
    EventBus.$off('driver-pay:set-reviewed', this.handleMarkAsDraft)
    EventBus.$off('driver-pay:set-draft', this.handleMarkAsDraft)
    EventBus.$off('driver-pay:create-paystubs', this.handleCreatePayStubs)
    EventBus.$off(
      'driver-pay:generate-pay-report-from-invoice-ids',
      this.generatePayReportFromInvoiceIds
    )
    EventBus.$off('driver-pay:open-contact', this.openContact)
    EventBus.$off('driver-pay:open-company', this.openCompany)
  }
}


import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  DriverPaymentPaystub,
  DriverPaymentInvoiceDetail,
} from '@/models/DriverPay'
import { currencyFilter } from '@/utils/string'
@Component({})
export default class PaystubDetailSidebarSummaryFooter extends Vue {
  @Prop({ required: true }) paystub!: DriverPaymentPaystub

  currencyFilter = currencyFilter

  get invoices(): DriverPaymentInvoiceDetail[] {
    return this.paystub.invoices
  }
}

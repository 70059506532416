
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import PaystubDetailSidebarHeader from './PaystubDetailSidebarHeader.vue'
import paystubStore from '@/store/modules/paystub'
import {
  DriverPaymentInvoiceDetail,
  DriverPaymentPaystub,
  UpdatePaystubsBulkRequest,
} from '@/models/DriverPay'
import PaystubDetailSidebarReservationTable from './PaystubDetailSidebarReservationTable.vue'
import PaystubDetailSidebarChargesTable from './PaystubDetailSidebarChargesTable.vue'
import PaystubDetailSidebarDetailHeader from './PaystubDetailSidebarDetailHeader.vue'
import PaystubDetailSidebarSummaryFooter from './PaystubDetailSidebarSummaryFooter.vue'
import PayStubDetailSidebarRatesFooter from './PayStubDetailSidebarRatesFooter.vue'
import PaystubDetailSidebarMarkAsPaidModal from './PaystubDetailSidebarMarkAsPaidModal.vue'
import PaystubDetailSidebarRevertModal from './PaystubDetailSidebarRevertModal.vue'
import dayjs from 'dayjs'
import client from '@/services/driverpay'
import { EventBus } from '@/utils/eventBus'
import sidebar from '@/store/modules/sidebar'
import DriverPaySendToDriverSidebar from './DriverPaySendToDriverSidebar.vue'
import { apiBaseUrl } from '@/utils/env'
@Component({
  components: {
    PaystubDetailSidebarHeader,
    PaystubDetailSidebarReservationTable,
    PaystubDetailSidebarChargesTable,
    PaystubDetailSidebarDetailHeader,
    PaystubDetailSidebarSummaryFooter,
    PayStubDetailSidebarRatesFooter,
    PaystubDetailSidebarMarkAsPaidModal,
    PaystubDetailSidebarRevertModal,
  },
})
export default class PaystubDetailSidebar extends mixins(DateMixin) {
  displayMarkAsPaid = false
  displayRevertModal = false
  get paystub(): DriverPaymentPaystub {
    return paystubStore.paystub
  }

  get invoices(): DriverPaymentInvoiceDetail[] {
    if (this.paystub?.invoices) {
      return this.paystub.invoices.sort((a, b) => {
        if (a.start === b.start) {
          return a.reservationId - b.reservationId
        }
        return dayjs(a.start).isBefore(dayjs(b.start)) ? -1 : 1
      })
    }

    return []
  }

  get paystubTitle(): string {
    return this.paystub?.label || ''
  }

  get paystubStatus(): string {
    return this.paystub?.status || ''
  }

  get isPaid(): boolean {
    return this.paystubStatus === 'PAID'
  }

  get isReverted(): boolean {
    return this.paystubStatus === 'REVERTED'
  }

  get paymentDateLabel(): string {
    if (!this.paystub.paidDate) {
      return ''
    }
    const paystubDate = dayjs(this.paystub.paidDate).toISOString()
    return this.formatLongDate(paystubDate)
  }

  handleMarkAsPaid(): void {
    this.displayMarkAsPaid = true
  }

  handleDisplayRevertModal(): void {
    this.displayRevertModal = true
  }

  handleViewPaystubPDF(): void {
    const url = `https://${apiBaseUrl('pdf')}/pdf/driver-pay-detail-${
      this.paystub.id
    }.pdf`
    window.open(url)
  }

  handleDisplaySendToDriverSidebar(): void {
    const driver = { ...this.paystub.driver, type: 'Driver' }

    sidebar.push({
      component: DriverPaySendToDriverSidebar,
      props: {
        recipients: [driver],
        paystubs: [this.paystub],
      },
      title: 'Send to Driver',
    })
  }

  async handleConfirm(paymentDate: string): Promise<void> {
    const payload: UpdatePaystubsBulkRequest = {
      payStubIds: [paystubStore.paystub.id],
      paidOn: dayjs(paymentDate).toISOString(),
      status: 'PAID',
    }

    try {
      const res = await client.updatePaystubsBulk(payload)
      if (res.status === 200) {
        paystubStore.markAsPaid(paymentDate)
        this.$emit('refresh')
      }
      this.displayMarkAsPaid = false
    } catch (err) {
      console.log(err)
      EventBus.$emit('snackbar:error', 'Error updating paystub')
    }
  }

  async handleRevert(): Promise<void> {
    const payload: UpdatePaystubsBulkRequest = {
      payStubIds: [paystubStore.paystub.id],
      status: 'REVERTED',
    }

    try {
      const res = await client.updatePaystubsBulk(payload)
      if (res.status === 200) {
        paystubStore.markAsReverted()
        this.$emit('refresh')
      }
      this.displayRevertModal = false
    } catch (err) {
      console.log(err)
      EventBus.$emit('snackbar:error', 'Error updating paystub')
    }
  }
}


import { Contact } from '@/models/Contact'
import { Paystub } from '@/models/DriverPay'
import auth from '@/store/modules/auth'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import RecipientList from './RecipientList.vue'
import driverPay from '@/services/driverpay'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
@Component({
  components: { RecipientList },
})
export default class DriverPaySendToDriverSidebar extends Vue {
  @Prop({ required: false, default: () => [] }) readonly recipients!: Contact[]
  @Prop({ required: true }) paystubs!: Paystub[]

  contacts = []
  loading = false

  @Watch('recipients', { immediate: true })
  onRecipientsChange(newRecipients: Contact[]): void {
    this.contacts = newRecipients
  }

  cancel(): void {
    sidebar.pop()
  }

  async submit(): Promise<void> {
    this.loading = true
    const recipients = this.contacts.map((contact) => contact.email)
    const payStubIds = this.paystubs.map((paystub) => paystub.id)
    const companyId = auth.getCompanyId
    const startDate = this.paystubs
      .map((p) => p.earliestPickupTimestamp)
      .sort()[0]
    const endDate = this.paystubs
      .map((p) => p.latestDropoffTimestamp)
      .sort()
      .reverse()[0]
    const driverName = this.paystubs[0].driver.name
    const payload = {
      recipients,
      payStubIds,
      driverName,
      companyId,
      startDate,
      endDate,
      label: this.paystubs[0].label,
    }

    try {
      const res = await driverPay.sendToDriver(payload)
      if (res.status === 200) {
        EventBus.$emit('snackbar:success', 'Pay Stub sent successfully')
        sidebar.pop()
      }
    } catch (e) {
      EventBus.$emit('snackbar:error', 'Pay Stub failed to send')
    } finally {
      this.loading = false
    }
  }
}

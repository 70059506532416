
import DriverPaySummary from '@/components/DriverPaySummary.vue'
import { Component, Vue } from 'vue-property-decorator'
import Main from '@/layouts/Main.vue'

@Component({
  components: {
    DriverPaySummary,
    Main,
  },
})
export default class DriverPay extends Vue {}


import { Contact } from '@/models/Contact'
import { Paystub } from '@/models/DriverPay'
import sidebar from '@/store/modules/sidebar'
import { Vue, Component, Prop } from 'vue-property-decorator'
import RecipientList from '@/components/RecipientList.vue'
import driverPay from '@/services/driverpay'
import auth from '@/store/modules/auth'

@Component({
  components: { RecipientList },
})
export default class DriverPaySendPaymentSummarySidebar extends Vue {
  @Prop({ required: false, default: () => [] }) readonly recipients!: Contact[]
  @Prop({ required: true }) paystubs!: Paystub[]

  contacts = []
  loading = false

  cancel(): void {
    sidebar.pop()
  }

  async submit(): Promise<void> {
    // TODO: Wire up to API
  }

  async mounted(): Promise<void> {
    try {
      const res = await driverPay.getCompanyDefaults(auth.getCompanyId)
      const settings = res.data
      const { pmFirstName, pmLastName, pmEmail } = settings
      const payrollContact = {
        name: `${pmFirstName} ${pmLastName}`,
        email: pmEmail,
        type: 'Payroll Manager',
      }
      this.contacts = [payrollContact]
    } catch (err) {
      console.error(err)
    }
  }
}

export type PaystubStatus = 'PENDING' | 'REVERTED' | 'PAID'

export interface Paystub {
  id: string
  label: string
  status: PaystubStatus
  paidDate: string
  earliestPickupTimestamp: string
  latestDropoffTimestamp: string
  total: number
  driver: {
    userId: string
    name: string
  }
  reservationManagedIds: string[]
}
export interface DriverPaymentChargeDetail {
  rate: string
  amount: number
  count: number
}

export interface DriverPaymentCharge {
  id: string
  invoiceId: string
  chargeType: string
  total: number
  notes: string | null
  createdOn: string
  details?: any
  rateTypeId?: string
  rateTypeLabel?: string
  delete?: boolean
  name?: string
  date?: string
  driverCount?: number
}

export interface DriverVehicle {
  id: number
  name: string
}

export interface DriverPaymentInvoiceDetail {
  id: string
  reservationId: number
  driverId: number
  start: Date | string
  startTimezone: string
  stop: Date | string
  stopTimezone: string
  totalCharges: number
  totalTime: number
  paystubId: string
  status: string
  bookingContactId: number
  bookingContact: { id: number; name: string; email: string }
  vehicle: DriverVehicle
  pickupLocation: string
  charges: DriverPaymentCharge[]
}

export interface DriverPayInvoiceSummaryUser {
  userId: number
  name: string
  email: string
}

export interface DriverPayInvoiceSummary {
  bookingContact: DriverPayInvoiceSummaryUser
  reservationId: number
  managedId: string
  start: string
  startTimezone: string
  stop: string
  stopTimezone: string
  status: string
  totalCharges: number
  driver: DriverPayInvoiceSummaryUser
  company: any
  _typename: string
}

export interface DriverPaymentPaystub {
  id: string
  label: string
  status: string
  driver: DriverPayInvoiceSummaryUser
  paidDate: Date | string | null
  invoices: DriverPaymentInvoiceDetail[]
  total: number
}

export interface PaystubPagedResult {
  content: Paystub[]
  totalElements: number
  totalPages: number
  numberOfElements: number
}

export interface UpdateChargesRequest {
  invoiceId: string
  start: string
  stop: string
  companyId: number
  charges: DriverPaymentCharge[]
}

export interface UpdatePaystubsBulkRequest {
  payStubIds: string[]
  status: PaystubStatus
  paidOn?: string
}

export interface SendPaystubsRequest {
  recipients: string[]
  payStubIds: string[]
  companyId: number
  label: string
}

export interface DriverRate {
  id?: string
  companyId?: number
  driverId?: number
  vehicleType?: DriverRateVehicleType
  hourlyRate: number | null
  dailyRate: number | null
  longTripMinHours: number | null
  overnightMinHours: number | null
  percentageBase: number | null
  percentageTotal: number | null
  perTripMinHours: number | null
  perTripMinRate: number | null
  perDiem: number | null
  delete?: boolean
}

export interface DriverRateVehicleType {
  id: number
  label: string
}

export const ChargeTypes = {
  REIMBURSEMENT: 'REIMBURSEMENT',
  PERCENTAGE: 'PERCENTAGE',
  PER_TRIP: 'PER_TRIP',
  PER_DIEM: 'PER_DIEM',
  GRATUITY: 'GRATUITY',
  HOURLY: 'HOURLY',
  DAILY: 'DAILY',
}

export const ChargeSuffixes = {
  REIMBURSEMENT: '',
  PER_TRIP: 'trip',
  PERCENTAGE: '%',
  PER_DIEM: 'day',
  HOURLY: 'hr',
  DAILY: 'day',
  GRATUITY: '',
}

export interface AggregatedChargeInfo {
  vehicleTypeName: string
  rate: string
  rateTypeId?: string
  rateTypeLabel?: string
  total: number
  count: number
  amount: number
  suffix: string
}

export interface CalculationType {
  id: number
  key: string
  name: string
}

export interface RateType {
  id: string
  label: string
  isActive: boolean
  baseRateKey: string
  isDefault: boolean
  isHidden: boolean
  orderIndex: number
}
